






import Vue from 'vue';

import { loginWithAzure } from '@/azureAd';
import Loader from '@/components/Loader.vue';

export default Vue.extend({
  name: 'azure-login',

  components: { Loader },

  created() {
    loginWithAzure();
  },
});
